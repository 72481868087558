@import "../sharedstyles/variables";
@import "../sharedstyles/mixins";

.sliderContainer {
    display: flex;
    position: relative;
}
:global(.fslightbox-source-inner){
    .gamesItem{
        width:100%!important;
        height:auto!important;
        // @include mq("tablet") {
        //     .largeText{
        //         font-size: 100px;
        //         line-height: 100px;
        //     }
        //     .smallText{
        //         font-size: 80px;
        //         line-height: 80px;
        //     }
        // }
    }
}

.gamesItem {
    position: relative;
    // overflow: hidden;
    @include mq("tablet-small", max) {
        height: 300px;
    }
    
    @include mq("tablet-wide") {
        height: auto;
    }
    svg{
        width:90%;
    }
}

:global(.tns-item) .gamesTile{
    @include mq("phone", max) {
        width: 273px;
        height: 273px;
    }
    @include mq("tablet-small", max) {
        width:300px;
        height: 300px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
}


.gameBackground{
    width:100%;
    height:100%;
    position: relative;
    border-radius: 5px;
}
.gamePlayerSizer{
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 33%;
  padding-bottom: 33%;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #A12962;
  
  .gamePlayer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.gameMessage{
    width:100%;
    text-align: center;
    font-family: 'BebasNeue-Bold', sans-serif;
    position: absolute;
    top: 70%;
    color: white;
    font-size: 42px;
    fill: #fff;
    line-height: 42px;
    // &.largeText{
    //     font-size: 42px;
    //     //line-height: 50px;
    // }
    // &.smallText{
    //     font-size: 42px;
    //     //line-height: 42px;
    // }
}
.playerName{
    text-shadow: 0px 0px 8px #FFF;
}

// .gamesItem img {
//     width: 335px;
//     height: 335px;

//     margin: auto;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);

//     border-radius: 8px;
//     @include mq("tablet-wide") {
//         width: 100%;
//         // height: auto;
//         // position: relative;
//         // transform: none;
//         // top:0;
//         // left:0;
  
//     }
// }