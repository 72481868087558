@import "../sharedstyles/variables";
@import "../sharedstyles/mixins";
.BannerContainer{
  max-width: 1100px;
  margin: auto;

  a{
    display: flex;
    img{
      max-width: 100%;
    }
  }
  .BannerDesk{
    display: none;
  }

  @include mq("tablet-wide") {
    .BannerDesk{
      display: block;
    }
    .BannerMob{
      display: none;
    }
  }

}