@import "../sharedstyles/variables";
@import "../sharedstyles/mixins";

.sliderContainer {
    display: flex;
    position: relative;
}

.groupPhotoItem {
     height: 200px;
    @include mq("tablet-small") {
        height: auto;
  
    }
}

.groupPhotoItem img {
    width: 335px;
    height: 200px;

    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    @include mq("tablet-small") {
        width: 100%;
        height: auto;
        position: relative;
        transform: none;
        top:0;
        left:0;
  
    }
    @include mq("phone", max) {
        width: 300px;
        height: auto;
    }
}