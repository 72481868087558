@import "../sharedstyles/variables";
@import "../sharedstyles/mixins";

.header {
    width: 100%;
    height: 300px;
    position: relative;
    font-family: 'Didot-Italic', sans-serif;
    font-size: 23px;
    display: flex;
    overflow: hidden;
    @include mq("tablet-wide") {
        height: 600px;
        font-size: 46px;
    }
}
.feedback{
    background-color: #72BBC5;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'BrandonGrotesque-Regular', sans-serif;
    div{
        text-align: center;
        margin:7px;
    }
    a,:visited{
        color: black;
        font-weight: bold;
    }
}

.photo {
    position: relative;
    background-color: white;
    width:100%;
}

.photoFade {
    position: absolute;
    top: 0;
    width: 100%;
    height: 300px;
    // background-color: rgba(20, 150, 240, 0.8);
    // background-color: rgba(11, 142, 194, 0.965);
    @include mq("tablet-wide") {
        height: 600px;
    }
    mix-blend-mode: multiply;
    transform: translateZ(0);
}

.photo img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-45%);
    height: 116%;
    min-width: 102%;
    min-height: 57.25vw;

    // position: relative;
    // width:100%;
    // height: 300px;
    // transform: scale(1.2);
    // object-fit: cover;
    // object-position: 50% 50%;
    // @include mq("tablet-wide") {
    //     height: 600px;
    // }
}

.videoContainer{
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    //background-color: rgba(20, 150, 240, 1);
    mix-blend-mode: multiply;
}
.videoContainer iframe{
    height: 116%;
    width: 177.77777778vh;
    min-width: 102%;
    min-height: 57.25vw;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -44%);
}


.logoContainer {
    width: 50%;
    margin: auto;
    position: absolute;
    top:50%;
    left: 50%;
    color: #ffffff;
    text-align: center;
    transform: translate(-50%, -50%);
    max-width:500px;   
    @include mq("tablet", max) {
      width: 40%;
      max-width:350px;  
    }
    @include mq("phablet", max) {
      width: 75%;
      max-width:280px;  
    }
}

.logo {
    position: relative;
    margin: 8px auto 0px;
    @include mq("tablet-wide") {
        margin: 8px auto 0px;
    }
    img{
        width:100%;
    }
}
.positionlogo{
  margin-top: -18%;
}

.welcome, .venue {
    position: relative;
    text-shadow: 0px 0px 4px #000000, 0px 0px 6px #000000, 0px 0px 8px #000000;
    // text-shadow: 0px 0px 6px #000000, 0px 0px 8px #000000, 0px 0px 10px #000000;
    // text-shadow: 0px 0px 8px #000000, 0px 0px 10px #000000, 0px 0px 12px #000000;
}
.tournamentVenue{
  margin-top: -11%;
}

.logoShadow {
    position: absolute;
    text-align: center;
    width:100%;
    transform: translate(-50%, -50%);
    top:50%;
    left: 50%;
    img{
      width:100%;
    }
}

.ribbon{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65px;
  font-size: 30px;
  
  div{
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .ribbonLeft{
    width: 18%;
    min-width: 40px;
    background-position: right;
    margin-right: -1px;
  }
  .ribbonCenter{
    min-width:30%;
    div{
      margin: 12px -20px 0 -20px;
      z-index: 1000;
      position: relative;
    }
  }
  .ribbonRight{
    width: 18%;
    min-width: 40px;
    background-position: left;
    margin-left: -1px;
  }

  @include mq("tablet", max) {
    height: 55px;
    font-size: 20px;  
    .ribbonCenter{
      div{
        margin: 12px -10px 0 -10px;
      }
    }
  }
}