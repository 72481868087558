@import "../sharedstyles/variables";
@import "../sharedstyles/mixins";

.playersContainer {
    display: flex;
    position: relative;
}

// .playerItem {
//     width: 125px;

// }

.playerImage{
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom:10px;
    @include mq("tablet-wide") {
        width: 85%;
        margin-left:auto;
        margin-right:auto;
    }
    .playerImageSizer{
      position: relative;
      overflow: hidden;
      border-radius: 50%;
      width:100%;
      padding-bottom: 100%;
      box-shadow: 3px 6px 6px rgba(0, 0, 0, 0.16);
      img{
        position:absolute;
        left: 50%;
        top:50%;
        transform: translate(-50%, -50%);
        width: auto;
        height: 125px;
        min-width: 100%;
        min-height: 100%;
      }
    }
}

.points{
    color: #fff;
    width: 36%;
    max-width: 60px;
    justify-content: center;
    display: flex;
    align-items: center;
    border: 1px solid #55BEC7;
    background: #55BEC7;
    border-radius: 50%;
    position: absolute;
    bottom:0;
    right:0;
    text-align: center;
    font-size: 30px;
    font-family: 'BebasNeue-Bold', sans-serif;
    @include mq("desktop") {
        // width: 60px;
        // height: 60px;
        // line-height: 60px;
        // bottom:-10px;
        font-size: 45px;
    }
    .pointsHeight{
        margin-top: 100%;
    }
}

.playerName {
    font-family: 'BrandonGrotesque-Regular', sans-serif;
    font-size: 19px;
    text-align: center;
    margin: 2px 0 0;
    text-transform: capitalize;
    sup{font-size: 10px;}
    @include mq("tablet-wide") {
        font-size: 28px;
        sup{font-size: 20px;}
    }
       
}

.teamItem{
    position: relative;
    padding-top:50px;
    &:first-of-type{
        .teamPlayers{
            border: 3px solid #55BEC7;
        }
        .teamTitle{
            &:before{
                position: absolute;
                top: -25px;
                transform: translateX(-50%);
                left:50%;
                white-space: nowrap;
                content: 'WINNING TEAM!';
                font-family: 'BebasNeue-Bold', sans-serif;
                font-size: 28px;
                font-weight: normal;
                text-transform: uppercase;
                text-align: center;
                margin: 0 auto 0;
                padding: 0;
                color: #55BEC7;
                @include mq("tablet-wide") {
                font-size: 30px;
                }
            }
        }
        .playerImage img{
            border:3px solid #55BEC7;
            box-sizing: border-box;
        }
    }
    .teamTitle{
        position: absolute;
        background:#F2EFEA;
        transform: translateX(-50%);
        left:calc(50% - 15px);
        padding: 5px 15px;
        top:20px;
        display: flex;
        align-items: center;
        
        .points{
            margin-left:15px;
            color: #fff;
            width: 40px;
            justify-content: center;
            display: flex;
            align-items: center;
            border: 1px solid #55BEC7;
            background: #55BEC7;
            border-radius: 50%;
            position: relative;
            bottom:0;
            right:0;
            text-align: center;
            font-size: 30px;
            font-family: 'BebasNeue-Bold', sans-serif;
            @include mq("tablet-wide") {
                font-size: 45px;
                width:55px;
            }
            .pointsHeight{
                margin-top: 100%;
            }
        }
    }
    .teamPlayers{
        padding:40px 20px 20px 20px;
        border: 1px solid black;
        display:flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        .playerItem {
            width: 30%;
            
            @include mq("phablet", max) {
                width: 40%;
            }
          
            .playerImage{
                margin-bottom: 0;
                img {
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;
                    box-shadow: 3px 6px 6px rgba(0, 0, 0, 0.16);
                    @include mq("tablet-wide") {
                        
                    }
                }
            }
            .playerName{
                margin:2px 0 15px 0;
            }
        
        }
        
    }

}

.sessionGroup{
    padding-top:35px;
    position: relative;
    &:first-of-type{
        .playerItem{
            &:first-of-type{
              .playerImageSizer{
                    border:3px solid #55BEC7;
                    box-sizing: border-box;
                }
            }
        } 
    }
    .playerImage{
      margin-bottom: 0;
    }
    .playerName{
      margin:2px 0 15px 0;
        span{
            display:none;
        }
    }

    .sessionWinner{
        font-family: 'BebasNeue-Bold', sans-serif;
        font-size: 30px;
        color: #55BEC7;
        position: absolute;
        height: 30%;
        width: 100%;
        top:0;
        span{
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 20px;
            height: 100%;
            text-align: center;
            transform-origin: bottom center;
            &:nth-of-type(1)  {
                transform: translateX(-50%) rotate(-27deg);
            }
            &:nth-of-type(2)  {
                transform: translateX(-50%) rotate(-18deg);
            }
            &:nth-of-type(3)  {
                transform: translateX(-50%) rotate(-9deg);
            }
            &:nth-of-type(4)  {
                transform: translateX(-50%) rotate(0deg);
            }
            &:nth-of-type(5)  {
                transform: translateX(-50%) rotate(9deg);
            }
            &:nth-of-type(6)  {
                transform: translateX(-50%) rotate(18deg);
            }
            &:nth-of-type(7)  {
                transform: translateX(-50%) rotate(27deg);
            }
        }
    }
   
}