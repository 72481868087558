@import "../sharedstyles/variables";
@import "../sharedstyles/mixins";

:global(.tns-outer){
  width:335px;
  margin:auto;
  @include mq("tablet-wide") {
    width: 80%;
    overflow: hidden;
  }
}
:global(.teams_section .tns-outer), :global(.session_section .tns-outer){
  width:85vw;
  margin:auto;
  @include mq("tablet-wide") {
    width: 80%;
    overflow: hidden;
  }
}

:global(.players_section .tns-outer){
  width:300px;
  margin:auto;
  @include mq("phone-wide") {
    width: 400px;
  }
  @include mq("tablet-small") {
    width: 600px;
  }
  @include mq("tablet-wide") {
    width: 80%;
    overflow: hidden;
  }
  :global(.tns-item){
    max-width:250px;
  }
}
:global(.tns-ovh) {
  overflow: visible;
}
:global(.tns-item){
  transition: all 0.2s;
  /* transform: scale(0.8);  */
}
:global(.tns-item img){
  width: 100%;
}
:global(.tns-slide-active){
  transform: scale(1);
  z-index: 1000;
}

:global(.tns-slider){
  display: flex;
  justify-content: center;
}
:global(.tns-visually-hidden){
  display: none;
}

:global(.games_section.scale-slider .tns-outer){
  width:280px;
  @include mq("phone", max) {
    width: 250px;
  }
  @include mq("tablet-small") {
    width: 80%;
  }
  @include mq("tablet-wide") {
    width: 80%;
    overflow: hidden;
  }
  :global(.tns-item){
    max-width:500px;
  }
}
:global(.scale-slider .tns-outer){
  width:300px;

  @include mq("phone", max) {
    width: 273px;
  }
  @include mq("tablet-small") {
    width: 80%;
  }
  @include mq("tablet-wide") {
    width: 80%;
    overflow: hidden;
  }

}
:global(.iframeContainer){
  width:100%;
  padding-bottom:56.25%;
  position: relative;
}
:global(.sliderVideoPlayer){
 pointer-events: none;
}

/* :global(.scale-slider .tns-slide-active img){
  margin:auto;
}
:global(.scale-slider .tns-slide-active~.tns-item img){
  margin-left:-32px;
} */

