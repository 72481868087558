@import "../sharedstyles/variables";
@import "../sharedstyles/mixins";

.button{
  position: absolute;
  width:10%;
  height:100%; 
  display:flex;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 4;
  @include mq("tablet-wide", max) {
    display:none;
  }
  :global(.section:nth-child(odd)) & {
    background: rgba(242, 239, 234, 0.5);
  }
}

.grey{
  background-color: rgba(242, 239, 234, 0.5);
  
}
.imageContainer{
  width:15%;
  margin:auto;
}

.next{
  right:25px;
}
.previous{
  left:25px;
}
.next, .previous{
  &[aria-disabled="true"] {
    opacity: 0;
  }
}