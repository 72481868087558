.ShareBar {
    z-index: 3;
    left: 0;
    display: flex;
    width: 100%;
    justify-content: center;

    padding-left: 4px;
}
.shareButton {
  display: flex;
  justify-content: center;
  font-size: 13px;
  text-align: center;
  cursor: pointer;
  img{
    max-width: 22px;
    height: 22px;
    margin: 7px 14px;
    //box-shadow: 5px 5px 10px -3px #000;
  }
}