.gamesTile{
  background-repeat-y: no-repeat;
  background-size: contain;
  position: relative;
}
.gameBackground{
  max-width:100%
}
.gamePlayer{
  position:absolute;
  z-index: 1;
  top: 50%;
  left: 25%;
  width:32%;
  // padding-bottom: 32%;
  transform: translate(-50%, -50%);  
  .gamePlayerSizer{
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding-bottom: calc(100% - 10px);
    overflow: hidden;
    border-radius: 50%;
    border: 5px solid #E6DED8;
    box-shadow: 0px 0px 5px #FFF;
  }
  img{
    position: absolute;
    transform: translate(-50%, -50%);
    top:50%;
    left:50%;
    min-height: 100%;
    min-width: 100%;
    height:20px;
  }

  &:after{
    content:'';
    position: absolute;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    top: calc(50% );
    left: 50%;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    border: 2px solid #BCBBBC;
  }
}
.gameMessage{
  position: absolute;
  z-index: 1;
  top: 33%;
  left: 70%;
  width:51%;
  transform: translate(-50%, -50%);

  text-align: center;
  font-family: 'BebasNeue-Bold', sans-serif;
  color: white;
  font-size: 42px;
  fill: #fff;
  line-height: 42px;
  
}

.gameLogo{
  position: absolute;
  z-index: 1;
  top: 67%;
  left: 70%;
  width:51%;
  transform: translate(-50%, -50%);
  img{
    width: 100%;
  }
}

.playerName{
  text-shadow: 0px 0px 8px #FFF;
}