@import "../sharedstyles/variables";
@import "../sharedstyles/mixins";

.sliderContainer {
    display: flex;
    position: relative;
}

.actionReplayItem {
    // height: 200px;
    
}

.actionReplayItem iframe {
    width: 335px;
    height: 200px;
    pointer-events: none;

    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    @include mq("tablet-small") {
        width: 100%;
        height: 100%;
      }
    @include mq("phone", max) {
        width: 300px;
    }
}

:global(.fslightbox-source-inner){
    .actionReplayItem{
        width:80vw!important;
        height:auto!important;
    }
}

