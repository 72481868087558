.loadingStory {
    width: 100%;
    height: 100vh;
    position: relative;
    font-family: 'Didot-Italic', sans-serif;
    font-size: 23px;
    display: flex;
    overflow: hidden;
}

.logoContainer {
    width: 100%;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    color: #ffffff;
    text-align: center;
}

.logo {
    position: relative;
    margin: 0 auto -8px;
}

.welcome, .venue {
    position: relative;
}

.logoShadow {
    position: absolute;
    text-align: center;
    width:100%;
    top: -20px;
}