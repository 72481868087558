@import-normalize; /* bring in normalize.css styles */
@import "./sharedstyles/variables";
@import "./sharedstyles/mixins";


@import url('https://fonts.googleapis.com/css?family=Oswald:400,500,700');
@font-face {
  font-family: 'BrandonGrotesque-Regular';
  src: url('./fonts/BrandonGrotesque-Regular.eot');
  src: url('./fonts/BrandonGrotesque-Regular.woff2') format('woff2'),
  url('./fonts/BrandonGrotesque-Regular.woff') format('woff'),
  url('./fonts/BrandonGrotesque-Regular.ttf') format('truetype'),
  url('./fonts/BrandonGrotesque-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'BebasNeue-Bold';
  src: url('./fonts/BebasNeueBold.eot');
  src: url('./fonts/BebasNeueBold.woff2') format('woff2'),
  url('./fonts/BebasNeueBold.woff') format('woff'),
  url('./fonts/BebasNeueBold.ttf') format('truetype'),
  url('./fonts/BebasNeueBold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Didot-Italic';
  src: url('./fonts/Didot-Italic.eot');
  src: url('./fonts/Didot-Italic.woff2') format('woff2'),
  url('./fonts/Didot-Italic.woff') format('woff'),
  url('./fonts/Didot-Italic.ttf') format('truetype'),
  url('./fonts/Didot-Italic.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'BebasNeue-Regular';
  src: url('./fonts/BebasNeueRegular.woff2') format('woff2'),
  url('./fonts/BebasNeueRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

h2 {
  font-family: 'BebasNeue-Bold', sans-serif;
  font-size: 30px;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto 20px;
  padding: 0;
  @include mq("tablet-wide") {
    font-size: 50px;
  }

  .session_section &, .teams_section & {
    margin: 0 auto 10px;
  }
}

h2:after {
  background: none repeat scroll 0 0 #000;
  content: "";
  display: block;
  width: 40px;
  height: 2px;
  border: 0;
  margin: 0 auto;
}

h3 {
  font-family: 'BebasNeue-Bold', sans-serif;
  font-size: 22px;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto 0;
  padding: 0;
  @include mq("tablet-wide") {
    font-size: 45px;
  }
}

.section {
  padding: 40px 0 50px 0;
  overflow: hidden;
  &:nth-child(odd) {
    background-color: #F2EFEA;
  }
}


.section.grey {
  background-color: #F2EFEA;
}
.section.dark {
  background-color: #0E1915;
}
.section.dark h2 {
  color: #fff;
}
.section.dark h2:after {
  background-color: #fff;

} 
.scrollContainer {
  overflow-x: scroll;
  padding: 0 10px;
}

